import React, { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

function FileUploader() {
    const toast = useRef(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const onUpload = (e) => {
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'Files Uploaded' });
        const response = JSON.parse(e.xhr.response);
        const fileData = response.fileNames.map((fileName, index) => ({
            name: fileName,
            originalUrl: response.originalFileURLs[index],
            resizedUrl: response.resizedFileURLs[index]
        }));
        setUploadedFiles(fileData);
    };

    const copyUrlToClipboard = (url) => {
        navigator.clipboard.writeText(url);
        toast.current.show({ severity: 'success', summary: 'Copied', detail: 'URL Copied to Clipboard' });
    };

    return (
        <div className="content">
            <h2> Image Here Generator .WEBP</h2>
            <Toast ref={toast} />
            <FileUpload name="demo[]" url="https://go.afaa.website/aachat/api/upload"
                onUpload={onUpload} multiple accept="image/*" maxFileSize={1000000}
                chooseOptions={{ icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' }}
                uploadOptions={{ icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' }}
                cancelOptions={{ icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' }}
                emptyTemplate={<p className="p-m-0">Drag and Drop Image Here</p>}
            />
            {uploadedFiles.length > 0 && (
                <DataTable value={uploadedFiles} className="p-datatable-striped">
                    <Column field="name" header="File Name"></Column>
                    <Column field="originalUrl" header="Original URL" body={rowData => (
                        <>
                            <span className="url-text">{rowData.originalUrl}</span>
                            <Button icon="pi pi-clipboard" className="p-button-rounded p-button-success ml-2" onClick={() => copyUrlToClipboard(rowData.originalUrl)} tooltip="Copy Original URL" />
                        </>
                    )}></Column>
                    <Column field="resizedUrl" header="Resized URL" body={rowData => (
                        <>
                            <span className="url-text">{rowData.resizedUrl}</span>
                            <Button icon="pi pi-clipboard" className="p-button-rounded p-button-success ml-2" onClick={() => copyUrlToClipboard(rowData.resizedUrl)} tooltip="Copy Resized URL" />
                        </>
                    )}></Column>
                </DataTable>
            )}
        </div>
    );
}

export default FileUploader;
