import React from 'react';
import { Menubar } from 'primereact/menubar';
import { Routes, Route } from 'react-router-dom';
import Gallery from './page/Gallery';
import FileUploader from './components/FileUploader';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './style/AppImg.scss';

function App() {
    const items = [
        { label: 'AA', icon: 'pi pi-fw pi-home', command: () => { window.location.href = '/'; } },
        { label: 'Gallery', icon: 'pi pi-fw pi-image', command: () => { window.location.href = '/gallery'; } },
        { label: 'Maracar Login', icon: 'pi pi-circle-on', url: 'https://maracar.rampagesoft.com/', target: '_blank' }
    ];

    return (
        <div className="App">
            <Menubar model={items} />
            <Routes>
                <Route path="/" element={<FileUploader />} />
                <Route path="/gallery" element={<Gallery />} />
            </Routes>
            <footer className="app-footer">
            Image Here V2.0 | © 2024
                <a href="https://afraapache.com/"> AFRA APACHE </a> All rights reserved.
            </footer>
        </div>
    );
}

export default App;
