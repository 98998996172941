import React, { useState, useEffect } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';

function Gallery() {
    const [data, setData] = useState([]);
    const [layout, setLayout] = useState('grid');

    useEffect(() => {
        // Mock data loading
        setTimeout(() => {
            setData([
                {id: 1, image: 'https://afaa.website/s/f9d9c0.webp', title: 'Item 1', description: 'Description 1'},
                {id: 2, image: 'https://afaa.website/s/f9d9c0.webp', title: 'Item 2', description: 'Description 2'}
            ]);
        }, 1500); // simulate network delay
    }, []);

    const renderListItem = (item) => {
        return (
            <div className="p-col-12">
                <Card title={item.title} style={{ border: '1px solid #333', backgroundColor: '#424242' }}>
                    {item.image ? <img src={item.image} alt={item.title} style={{ width: '200px', height: 'auto', display: 'block' }} /> : <Skeleton width="200px%" height="auto" />}
                    <p style={{ color: '#fff' }}>{item.description || <Skeleton width="80%" />}</p>
                </Card>
            </div>
        );
    };

    const renderGridItem = (item) => {
        return (
            <div className="p-col-12 p-md-4">
                <Card title={item.title} style={{ border: '1px solid #333', backgroundColor: '#424242' }}>
                    {item.image ? <img src={item.image} alt={item.title} style={{ width: '200px', height: 'auto', display: 'block', objectFit: 'cover' }} /> : <Skeleton width="100%" height="auto" />}
                    <p style={{ color: '#fff' }}>{item.description || <Skeleton width="80%" />}</p>
                </Card>
            </div>
        );
    };

    const itemTemplate = (item, layout) => {
        if (!item) {
            return;
        }

        if (layout === 'list') {
            return renderListItem(item);
        } else if (layout === 'grid') {
            return renderGridItem(item);
        }
    };

    return (
        <div>
            <h1 style={{ color: '#fff' }}>Gallery</h1>
            <DataView value={data} layout={layout} itemTemplate={itemTemplate}
                      paginator rows={9} header={<DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />}
                      emptyMessage="No items found." className="dataview-custom" />
        </div>
    );
}

export default Gallery;
